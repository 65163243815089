import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Walifacturas, WaliProformas } from 'app/core/shared/state';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentService {

    baseUrl = environment.baseApi + '/api/Payment';

    constructor(private http: HttpClient) {
    }

    getUserPayments(idUser) {
        return this.http.get(this.baseUrl + "/paid/iduser/" + idUser);
    };

    pay(idUser, code, licenseTypeUserIdArray) {
        if (code == null ||code == "")
            return this.http.post(this.baseUrl + "/pay/iduser/" + idUser, licenseTypeUserIdArray);
        else
            return this.http.post(this.baseUrl + "/pay/iduser/" + idUser + "/code/" + code, licenseTypeUserIdArray);
    };

    paySchool(idUser, numStudents, maxDate, iniDate, IdSchool) {
        return this.http.post(this.baseUrl + "/pay/iduser/" + idUser + "/school/" + IdSchool, { numStudents: numStudents, maxDate: maxDate, iniDate: iniDate });
    };

    getPaymentSchool(idUser) {
        return this.http.get(this.baseUrl + "/get/iduser/" + idUser + "/school/");
    };
    
    applyDiscount(idUser, code, licenseTypeUserIdArray) {
        return this.http.post(this.baseUrl + "/applyDiscount/iduser/" + idUser + "/code/" + code, licenseTypeUserIdArray);
    };

    cancel(idUserTutor, idUser, startDate) {
        return this.http.post(this.baseUrl + "/cancel/iduser/" + idUserTutor, { idUser: idUser, startDate: startDate } );
    };    

    getAllWaliFacturas() {
        return this.http.post(this.baseUrl + "/GetAllWaliFacturas", {});
    }

    getDataWalifacturas() {
        return this.http.post(this.baseUrl + "/getDataWalifacturas", {});
    }

    getDataPromotor() {
        return this.http.post(this.baseUrl + "/getDataPromotor", {});
    }

    getIVA() {
        return this.http.post(this.baseUrl + "/getIVA", {});
    }

    addWaliFactura(walifactura) {
        var params = { Walifactura: walifactura };
        return this.http.post(this.baseUrl + '/AddWaliFactura/', params);
    }

    public addWaliProforma(waliProforma): Observable<WaliProformas>  {
        return this.http.post<WaliProformas>(`${this.baseUrl}/AddWaliProforma`, waliProforma);
    }

    updateWalifactura(walifactura) {
        var params = { walifactura: walifactura };
        return this.http.post(this.baseUrl + '/UpdateWaliFactura/', params);
    }

    public updateWaliProforma(waliProforma): Observable<WaliProformas> {
        return this.http.post<WaliProformas>(`${this.baseUrl}/UpdateWaliProforma`, waliProforma);
    }

    public deleteWaliProforma(numProforma: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/DeleteWaliProforma/${encodeURIComponent(numProforma.replace('/', '.'))}`);
    }

    public createInvoiceFromProforma(waliProforma): Observable<Walifacturas> {
        return this.http.post<Walifacturas>(`${this.baseUrl}/CreateInvoiceFromProforma`, waliProforma);
    }

    public getNextWaliProformaNumber(): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/GetNextWaliProformaNumber`);
    }

    payWaliFactura(invoiceNumber) {
        return this.http.post(this.baseUrl + '/PayInvoice/', { InvoiceNumber: invoiceNumber });
    }

    paySchoolStudent(idClass, name, familyName, username) {
        return this.http.post(this.baseUrl + "/pay/iduser/schoolStudent/", { idClass: idClass, username: username, name: name, familyName: familyName });
    };

    updatePaySchoolStudent(idUser) {
        return this.http.post(this.baseUrl + "/pay/iduser/" + idUser +"/schoolStudent/", null );
    };
    ReclamarFactura(walifactura) {
        var params = { Walifactura: walifactura };
        return this.http.post(this.baseUrl + '/ReclamarFactura/', params);
    }
    EnviarFactura(walifactura) {
        var params = { Walifactura: walifactura };
        return this.http.post(this.baseUrl + '/SendBill/', params);
    }
    GetFacturaReclamos(NumFactura: string) {
        var params = { NumFactura };
        return this.http.post(this.baseUrl + '/getFacturaReclamos/', params);
    }
    DeleteUnpayedLicenses(IdPayment: number){
        return this.http.delete(this.baseUrl + "/IdPayment/" + IdPayment);
    }

}
