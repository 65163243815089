import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Session } from '../core/shared/state/models/session.model';
import { Observable } from 'rxjs';
import { MedalStepConditionsRange } from 'app/core/shared/state/models/medal-step-conditions-range.model';

@Injectable()
export class SessionService {

    baseUrl = environment.baseApi + '/api/Session';
    currentSession: Session;
    reto;

    constructor(private http: HttpClient) {
    }

    getSessionByUserMonthYear(idUser, month, year) {
        return this.http.get(this.baseUrl + '/' + idUser + '/' + month + '/' + year);
    }

    checkSessionCondition(idUser, idClass, activityId){
        return this.http.get(this.baseUrl + '/checkSessionCondition/' + idUser+ "/idClass/" + idClass + "/activityId/" + activityId);
    }

    isTodaySessionFinished(idUser) {
        return this.http.post(this.baseUrl + '/isTodaySessionFinished/' + idUser, {});
    }

    getSession(id) {
        return this.http.get<Session>(this.baseUrl + '/' + id);
    }

    createTmpSession(idUser, onDemand) {
        return this.http.post(this.baseUrl + '/tmp/' + idUser + '/' + onDemand, {});
    };

    insertGlobalResult(idSession) {
        return this.http.post(this.baseUrl + '/insertGlobalResult/' + idSession, {});
    };

    updateSession(session, isAdmin) {
        var aux = {
            IdSession: session.IdSession,
            ExecutionDate: session.ExecutionDate,
            Status: session.Status,
            IsAdmin: isAdmin || false,
        };
        return this.http.put(this.baseUrl + '/' + session.IdSession, aux);
    };

    
    

    canStartSession(idUser, selectedDay, copySession) {
        let params: FormData = new FormData();
        params.append("idUser", idUser)
        params.append("selectedDate", selectedDay)
        params.append("copySession", copySession)
        return this.http.post(this.baseUrl + '/canStart/' + idUser, params);
    };

    retrySession(idUser, idSession) {
        const sessionToRetry = {
            idUSer: idUser,
            idSession: idSession
        };

        return this.http.post(`${this.baseUrl}/RetrySession`, sessionToRetry);
    };

    getActualSchedule() {
        let currentDate = moment().toObject()
        var params = {
            Hours: currentDate.hours,
            Minutes: currentDate.minutes,
            Seconds: currentDate.seconds
        };
        return this.http.post(this.baseUrl + '/getActualSchedule/', params);
    };

    clone(session, sessionToClone) {
        var params = { IdSession: session, IdSessionToClone: sessionToClone };
        return this.http.post(this.baseUrl + '/clone/', params);
    };

    getAllSchedules() {
        return this.http.post(this.baseUrl + '/getAllSchedules/', {});
    };

    getSessionScoreConditionsRanges(){
        return this.http.get(this.baseUrl + "/GetSessionScoreConditionsRanges/")
    }

    public getMedalStepConditionsRanges() : Observable<MedalStepConditionsRange[]> {
        return this.http.get<MedalStepConditionsRange[]>(this.baseUrl + "/GetMedalStepConditionsRanges/")
    }
    getPrintedSession(level: number, reducedSession: boolean) {
        const url = `${this.baseUrl}/getPrintedSession/${level}/${reducedSession}`;
        return this.http.get<{ PDFs: string[] }>(url);
    }
    /*async listBlobsWithPrefix(sasToken, prefix: string): Promise<string[]> {
        const blobs: string[] = [];

        const response = await this.http.get(`${environment.filesUrl}/images/?&prefix=${prefix}&restype=container&comp=list&${sasToken}`, {
            responseType: 'text'
        }).toPromise();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response, 'application/xml');
        const blobNames = Array.from(xmlDoc.getElementsByTagName('Name'))
            .map(node => node.textContent)
            .filter(name => name && name.includes(prefix));
        for (const name of blobNames) {
            blobs.push(`${environment.filesUrl}/images/${name}`);
            
            }
        return this.getPreferredBlobs(blobs);
    }
    
    getPreferredBlobs(blobNames: string[]): string[] {
        const extensionPriority = ['.webp', '.png', '.jpg']; // Orden de prioridad

        return blobNames
            .map(name => ({
                name,
                priority: extensionPriority.findIndex(ext => name.endsWith(ext)) !== -1
                    ? extensionPriority.findIndex(ext => name.endsWith(ext))
                    : extensionPriority.length, // Asigna la prioridad máxima si no coincide con las extensiones
            }))
            .sort((a, b) => a.priority - b.priority) // Ordena por prioridad
            .map(blob => blob.name); // Extrae los nombres ordenados
    }*/
}
