import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class EmailConfigService {

  baseUrl = environment.baseApi + '/api/EmailConfiguration';

  constructor(private http: HttpClient) {
  }

  getConfiguration(idUser) {
    return this.http.get(this.baseUrl + '/idUser/' + idUser);
  };

  update(idUser, sessionReports, warningNoSession, nomail, warningmessages: boolean, warningalias: boolean, warningSkippedSession) {
    return this.http.put(this.baseUrl + '/' + idUser + '/' + sessionReports + '/' + warningNoSession + '/' + nomail + '/' + warningmessages + '/' + warningalias + '/' + warningSkippedSession, {});
  }

}
