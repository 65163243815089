import { style } from '@angular/animations';
import { AuthService } from './auth.service';
import { Injectable } from "@angular/core";
import {
    HttpClient,
} from "@angular/common/http";
import { environment } from "environments/environment";
import interact from "interactjs";
import { Exerc } from "app/core/shared/state";
import { ExerciseSolvedEnum } from "app/core/shared/enums/exercise-solved.enum";
import { FileResponse } from 'app/core/shared/state/models/file/file.model';
declare var $, d;

@Injectable()
export class ExerciseService {
    baseUrl = environment.baseApi + "/api/Exercise";
    currentExercise = null;
    showingSolution = false;
    exerciseEnded = false;
    exercise: any = null;
    numExercises: number = 1;
    dictionaryWordList = [];
    oks = [];
    kos = [];
    paused = false;
    horario = null;
    consoleErrormsg = [];
    timer = {
        interval: null,
        startTime: null,
        currentSeconds: null,
        fullSeconds: null,
    };
    review = false;
    imgUrls: FileResponse[] = [];
    subDirectory = "/images/Exercises/"
    pauseTime: Date;
    elapsedTimeInSeconds: number;

    constructor(private http: HttpClient,private authService: AuthService) {}

    public get exercisesSolvedEnum(): typeof ExerciseSolvedEnum{
        return this.exerciseSolvedEnum
    }
        
    public get exerciseSolvedEnum(): typeof ExerciseSolvedEnum{
        return ExerciseSolvedEnum
    }

    newExercise(exercise) {
        return this.http.post(this.baseUrl, exercise);
    }
    addExercisesToSession(exercises, idSession) {
        let params: FormData = new FormData();
        params.append("exercises", JSON.stringify(exercises));
        params.append("IdSession", idSession);
        return this.http.post(this.baseUrl + "/addExercises", params);
    }

    

    

    

    updateExercise(exercise, isStudentAdmin) {
        exercise.ElementsOK = JSON.stringify(this.oks);
        exercise.ElementsERR = JSON.stringify(this.kos);
        if (this.timer.fullSeconds != null && this.timeExpired() && this.oks.length == 0 && this.kos.length == 0 && !exercise.Result.includes("JMP") && !exercise.Result.includes("AVZ")) {
            exercise.Result = "OUT"
        }
        else if (this.timer.fullSeconds != null && this.timeExpired() && this.oks.length > 0 && this.kos.length == 0 && (this.oks.length < exercise.NumItemToEvaluate) && !exercise.Result.includes("JMP") && !exercise.Result.includes("AVZ")) {
            exercise.Result = "OK-OUT"
        }
        else if (this.timer.fullSeconds != null && this.timeExpired() && this.kos.length > 0 && this.oks.length < exercise.NumItemToEvaluate && !exercise.Result.includes("JMP") && !exercise.Result.includes("AVZ")) {
            exercise.Result = "ERR-OUT"
        }
        else if (!exercise.Result.includes("JMP") && !exercise.Result.includes("AVZ")  && this.oks.length == 0 && this.kos.length == 0){
            exercise.Result = "OUT"
        }
        if(exercise.Result == false){
            exercise.Result = "OUT"
        }
        var offset = -(new Date().getTimezoneOffset() / 60);
        var aux = {
            IdExercise: exercise.IdExercise,
            ElementsERR: exercise.ElementsERR,
            ElementsOK: exercise.ElementsOK,
            EndDate: exercise.EndDate,
            NumElementsERR: exercise.NumElementsERR,
            NumElementsOK: exercise.NumElementsOK,
            NumElementsNull: exercise.NumElementsNull,
            NumTotalERR: exercise.NumTotalERR,
            Result: exercise.Result,
            SkillCoinsWon: exercise.SkillCoinsWon,
            StartDate: exercise.StartDate,
            TimeCoinsWon: exercise.TimeCoinsWon,
            SecondsHelp: this.currentExercise?.SecondsHelp,
            isStudentAdmin: isStudentAdmin,
            MaxTimeSec: exercise.MaxTimeSec,
            SkillCoins: exercise.SkillCoins,
            TimeCoins: exercise.TimeCoins,
            timeZone: offset,
        };
        // si el proximo ejercicio usa teclado y no tiene bindeado el evento de escucha del teclado, va a fallar
        $(window).unbind("keypress");
        $(window).unbind("keydown");
        $(window).unbind("keyup");
        return this.http.put(
            this.baseUrl +
            "/" +
            exercise.IdSession +
            "/" +
            exercise.SessionOrder,
            aux
        );
    }

    findImgUrl(imageName: string){
        return /*this.sanitizer.bypassSecurityTrustUrl(*/"https://walinwa.blob.core.windows.net" + this.subDirectory + imageName + "?ngsw-bypass=true"/*)*/;
    }

    getNewExercise(exercise, level) {
        return this.http.get(
            this.baseUrl +
            "/idType/" +
            exercise.IdExerciseType +
            "/idThema/" +
            exercise.IdThema +
            "/level/" +
            level +
            "/idSubthema/" +
            exercise.IdSubthema +
            "/minLevel/" +
            exercise.MinLevel +
            "/maxLevel/" +
            exercise.MaxLevel +
            "/minNumWordOcurrences/" +
            exercise.MinNumWordOcurrences +
            "/minBookLevel/" +
            exercise.MinBookLevel +
            "/maxBookLevel/" +
            exercise.MaxBookLevel
        );
    }

    reset() {
        this.currentExercise = null;
        this.showingSolution = false;
        this.timer = {
            interval: null,
            startTime: null,
            currentSeconds: null,
            fullSeconds: null,
        };
        this.oks = [];
        this.kos = [];
        this.exerciseEnded = false;
    }

    setCurrentExercise(exercise: Exerc, review = false) {
        if (exercise == null) {
            this.currentExercise = exercise;
            return;
        }
        this.currentExercise = exercise;
        this.currentExercise.civilization = this.authService.currentUser.Civilization.ImgFolder
        if (review) {
            this.review = true;
            window.postMessage("CURRENT_EXERCISE_CHANGED", "*");
            return;
        }
        this.oks = [];
        this.kos = [];
        this.currentExercise.ElementsOK = JSON.stringify(this.oks);
        this.currentExercise.ElementsERR = JSON.stringify(this.kos);
        this.currentExercise.NumElementsOK = 0;
        this.currentExercise.NumElementsERR = 0;
        this.currentExercise.NumElementsNull = 0;
        this.currentExercise.Result = "OK";
        this.currentExercise.StartDate = new Date();
        this.review = false;
        this.startTimer();
        window.postMessage("CURRENT_EXERCISE_CHANGED", "*");
    }

    updateExercReto(exercise, isStudentAdmin) {
        exercise.ElementsOK = JSON.stringify(this.oks);
        exercise.ElementsERR = JSON.stringify(this.kos);  
        if (this.timer.fullSeconds != null && this.timeExpired() && this.oks.length == 0 && this.kos.length == 0) {
            exercise.Result = "OUT"
        }
        else if (this.timer.fullSeconds != null && this.timeExpired() && this.oks.length > 0 && this.kos.length == 0 && this.oks.length < exercise.NumItemToEvaluate) {
            exercise.Result = "OK-OUT"
        }
        else if (exercise.ExerciseModel != "rapid" && exercise.ExerciseModel != "pasap"  && this.timer.fullSeconds != null && this.timeExpired() && this.kos.length > 0 && this.oks.length < exercise.NumItemToEvaluate) {
            exercise.Result = "ERR-OUT"
        }
        var aux = {
            IdExercise: exercise.IdExercise,
            ElementsERR: exercise.ElementsERR,
            ElementsOK: exercise.ElementsOK,
            EndDate: exercise.EndDate,
            NumElementsERR: exercise.NumElementsERR,
            NumElementsOK: exercise.NumElementsOK,
            NumElementsNull: exercise.NumElementsNull,
            NumTotalERR: exercise.NumTotalERR ?? 0,
            Result: exercise.Result,
            SkillCoinsWon: exercise.SkillCoinsWon,
            StartDate: exercise.StartDate,
            TimeCoinsWon: exercise.TimeCoinsWon,
            SecondsHelp: exercise.SecondsHelp,
            isStudentAdmin: isStudentAdmin,
            MaxTimeSec: exercise.MaxTimeSec,
            SkillCoins: exercise.SkillCoins,
            TimeCoins: exercise.TimeCoins,
        };

        var value = this.http.put(
            this.baseUrl +
            "/exerciseReto/" +
            exercise.IdReto +
            "/" +
            exercise.IdGuest +
            "/" +
            exercise.SessionOrder,
            aux
        );
        return value;
    }

    getCurrentExercise(): Exerc {
        return this.currentExercise;
    }

    getExerciseDefinition() {
        if (this.currentExercise.ExerciseDefinition)
            return JSON.parse(this.currentExercise.ExerciseDefinition.replace(/(\r\n|\n|\r)/gm, ""));
        if (this.currentExercise.ExerciseJson)
            return JSON.parse(this.currentExercise.ExerciseJson.ExerciseDefinition.replace(/(\r\n|\n|\r)/gm, ""));
        return "";
    }

    getExerciseModel() {
        return this.currentExercise ? this.currentExercise.ExerciseModel : null;
    }

    getSeconds() {
        let now = new Date().getTime();
        return (now - this.timer.startTime) / 1000;
    }

    startTimer() {
        let exerciseDefinition = this.getExerciseDefinition();
        this.timer.fullSeconds = parseFloat(exerciseDefinition.tiempo);
        this.timer.startTime = new Date().getTime();
        let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        if (this.timer.fullSeconds == 0 || currentUser.MultTime === -1) { 
            window.postMessage("TIMER", "*")
            if(currentUser.MultTime === -1){
                this.timer.fullSeconds = 600
            }
            return;
        }
        
        this.timer.currentSeconds = 0
        this.exerciseEnded = false;
        this.timer.interval = setInterval(() => {
            if(this.exerciseEnded)return;
            if (!this.paused) this.timer.currentSeconds = this.getSeconds();
            else this.timer.startTime += 100;
            if (this.timer.fullSeconds == null) window.postMessage("EXERCISE_END", "*");
            if (this.timer.fullSeconds != null && this.timeExpired()) {
                if (this.currentExercise) this.currentExercise.Result = "OUT";
                window.postMessage(this.exercisesSolvedEnum[this.exerciseSolvedEnum.TIMER_END], "*");
                console.log("timer finish", this.currentExercise.SessionOrder,this.timer.currentSeconds)
                this.stopTimer();
            }
            window.postMessage("TIMER", "*"); 
        }, 100);
    }

    forceStopTimer() {
        clearInterval(this.timer.interval);
    }

    stopTimer() {
        this.exerciseEnded = true;
        if (this.currentExercise) this.currentExercise.EndDate = new Date();
        interact(".dropzone").off("drop", function (e) {
            return;
        });
        interact(".dropzone").on("drop", function (e) {
            return;
        });
        clearInterval(this.timer.interval);
    }

    timeExpired() {
        return this.getSeconds() >= this.timer.fullSeconds;
    }

    getFullSeconds() {
        return this.timer.fullSeconds;
    }

    getCurrentSeconds() {
        return this.timer.currentSeconds;
    }

    showSolution() {
        this.showingSolution = true;
    }

    disableShowSolution() {
        this.showingSolution = false;
    }

    isShowingSolution() {
        return this.showingSolution;
    }

    answer(item, ok, nOptions, restAnyWay = false) {
        if(this.review){
            return;
        }
        if (!this.currentExercise.NumTotalERR)
            this.currentExercise.NumTotalERR = 0;
        if (ok == true) {
            if (this.oks.indexOf(item) != -1) return;
            this.oks.push(item);
            this.currentExercise.NumElementsOK++;
        }
        if (ok == false) {
            this.currentExercise.NumTotalERR++;
            if (this.kos.indexOf(item) != -1 && !restAnyWay) return;
            this.kos.push(item);
            this.currentExercise.NumElementsERR++;
            this.currentExercise.Result = "ERR";
            window.postMessage("EXERCISE_ERROR", "*");
        }
        if (this.currentExercise.NumElementsOK >= nOptions) {
            
            this.endExercise();
        }
    }

    endExercise() {
        if(this.review) return;
        window.postMessage("EXERCISE_END", "*");
        this.stopTimer();
    }

    pauseTimer() {
        this.paused = true;
        this.pauseTime = new Date();
    }

    restartTimer() {
        this.elapsedTimeInSeconds = null;
        if (this.paused && this.pauseTime && this.currentExercise) {
            const restartTime = new Date();
            const elapsedMilliseconds = restartTime.getTime() - this.pauseTime.getTime();
            this.elapsedTimeInSeconds += elapsedMilliseconds / 1000;
            this.currentExercise.SecondsHelp += Math.round(this.elapsedTimeInSeconds);
            this.pauseTime = null;
        }
        this.paused = false;
    }

    exerciseIsEnded() {
        return this.exerciseEnded;
    }

    resetInteract() {

        if (!interact["initialized"]) return

        interact(".draggable").unset()

        interact(".dropzone").unset()

        interact["initialized"] = false

    }

    initInteract(callbacks: any) {

        this.resetInteract()

        if (interact["initialized"]) return

        // draggable
        interact(".draggable").draggable({
            manualStart: true,
            onmove: function (event) {
                var target = event.target;
                var x =
                    (parseFloat(target.getAttribute("data-x")) || 0) +
                    event.dx;
                var y =
                    (parseFloat(target.getAttribute("data-y")) || 0) +
                    event.dy;
                target.style.webkitTransform = target.style.transform =
                    "translate(" + x + "px, " + y + "px)";
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);
            },
            onend: function (event) {
                if (event.target.remove) event.target.remove();
            },
        })

        // draggable on move
        interact(".draggable").on("move", function (event) {
            var interaction = event.interaction;
            if (
                interaction.pointerIsDown &&
                !interaction.interacting() &&
                event.currentTarget.classList.contains("draggable")
            ) {
                var cloneChildId =
                    event.currentTarget.getAttribute("dragChildImg");
                if (cloneChildId) {
                    for (
                        var i = 0;
                        i < event.currentTarget.childNodes.length;
                        i++
                    ) {
                        if (
                            event.currentTarget.childNodes[i].id ==
                            cloneChildId
                        )
                            var original =
                                event.currentTarget.childNodes[i];
                    }
                } else {
                    var original = event.currentTarget;
                }
                var clone = original.cloneNode(true);
                clone.className = clone.className.replace(
                    /\bdrag-element-source\b/,
                    "drag-element-item"
                );
                original.parentElement.appendChild(clone);
                var marginVertical =
                    $(original).outerHeight(true) -
                    $(original).outerHeight() +
                    "px ";
                var marginHorizontal =
                    $(original).outerWidth(true) -
                    $(original).outerWidth() +
                    "px";
                clone.classList.add("element-moving")
                clone.style.position = "absolute";
                clone.style.left = $(original).position().left + "px";
                clone.style.top = $(original).position().top + "px";
                clone.style.width = original.clientWidth + "px";
                clone.style.zIndex = "501";
                clone.style.height = original.clientHeight + "px";
                if (original && original.id.includes("orden")) {
                    clone.style.margin = marginVertical + marginHorizontal;
                }
                if(original && original.id.includes("boxes4c")){
                    clone.style.width = (original.clientWidth + 1) + "px"
                }
                try {
                    interaction.start(
                        { name: "drag" },
                        event.interactable,
                        clone
                    );
                } catch (err) {
                    if (clone.remove) clone.remove();
                }
            } else {
                interaction.start(
                    { name: "drag" },
                    event.interactable,
                    event.currentTarget
                );
            }
        })

        // dropzone 
        interact(".dropzone").dropzone({
            overlap: "center"
        })

        // dropzone on drag enter
        interact(".dropzone").on("dragenter", function (event) {
            var dropRect = interact.getElementRect(event.target),
                dropCenter = {
                    x: dropRect.left + dropRect.width / 2,
                    y: dropRect.top + dropRect.height / 2,
                };
            var draggableElement = event.relatedTarget,
                dropzoneElement = event.target;
            dropzoneElement.classList.add("drop-target");
            draggableElement.classList.add("can-drop");
        })

        // dropzone on drag leave
        interact(".dropzone").on("dragleave", function (event) {
            event.target.classList.remove("drop-target");
            event.relatedTarget.classList.remove("can-drop");
        })

        // dropzone on drop activate
        interact(".dropzone").on("dropactivate", function (event) {
            event.target.classList.add("drop-active");
        })

        // dropzone on drop desactivate
        interact(".dropzone").on("dropdeactivate", function (event) {
            event.target.classList.remove("drop-active");
            event.target.classList.remove("drop-target");
        })

        // dropzone on drop
        interact(".dropzone").on("drop", (event) => {
            event.relatedTarget.textContent = "";
        });

        if (callbacks.onmove) {
            interact(".draggable").draggable({
                onmove: callbacks.onmove,
            });
        }

        if (callbacks.drop) {
            interact(".dropzone").dropzone({ overlap: "center" }).on("drop", (event) => {
                callbacks.drop(event)
            });
        }

        interact["initialized"] = true

    }
    // initDragging(options:any = {}) {
    //     new d.Dragging({
    //         dragging_zone: ".dragging-zone",
    //         ondragstart:  (event, element) => {
    //             if (options.ondragstart) options.ondragstart(event, element)
    //         },
    //         ondragend:  (event, element) => {
    //             if (options.ondragend) options.ondragend(event, element)
    //         },
    //         ondrop:  (event, element) => {
    //             if (options.ondrop) options.ondrop(event, element)
    //         },
    //         onmove:  (event, element) => {
    //             if (options.onmove) options.onmove(event, element)
    //         }
    //     })
    // }
}
